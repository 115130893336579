import React from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { navigate } from "gatsby"; //import navigate from gatsby
import ParticleComponent from "../components/particles";
import Footer from "../components/Footer";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { name: "", email: "", message: "" },
      active: false,
      errMessage: "",
      status: {},
    };
    this.recaptchaRef = React.createRef();
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onReset = this.onReset.bind(this);
    this.goToHome = this.goToHome.bind(this);
  }

  async handleSubmitForm(e) {
    e.preventDefault();
    this.setState({ active: true, status: {} });

    try {
      const recaptchaValue = await this.recaptchaRef.current?.executeAsync();
      //console.log("recaptcha value", recaptchaValue)

      if (recaptchaValue) {
        if (!this.state.formData?.name) {
          this.setState({ active: false, errMessage: "The Name is required" });
        } else if (!this.validateEmail(this.state.formData.email)) {
          this.setState({
            active: false,
            errMessage: "Invalid Email provided",
          });
        } else if (!this.state.formData?.message) {
          this.setState({
            active: false,
            errMessage: "You should provide your Message",
          });
        } else {
          const data = this.state.formData;
          data["captcha"] = recaptchaValue;

          axios
            .post(process.env.NI_API_URL + "/mail", data)
            .then((res) => {
              if (res.data.success) {
                console.log("response success", res.data);

                this.setState({
                  status: res.data,
                });

                // close conctact view and reset field
                setTimeout(() => {
                  this.setState({
                    status: {},
                    active: false,
                    formData: { name: "", email: "", message: "" },
                  });
                  this.goToHome();
                }, 3000);
              } else {
                console.log("response error", res.data);
                this.setState({ active: false, status: res.data });
              }
            })
            .catch((err) => {
              //console.log("catch error..", err, err.response);
              this.setState({ active: false, status: err });
            });
        }
      } else {
        this.setState({ active: false });
        console.log("captcha error, maybe token expired !!");
      }
    } catch (error) {
      console.log("err ", error);
      this.setState({ active: true });
    }
  }

  handleOnChange(e) {
    this.setState({ errMessage: "" });
    this.state.formData[e.target.name] = e.target.value;

    this.setState({
      formData: { ...this.state.formData },
    });
  }

  onReset() {
    this.setState({
      formData: { name: "", email: "", message: "" },
      active: false,
      errMessage: "",
      status: {},
    });
  }

  validateEmail(email) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  goToHome() {
    navigate("/");
  }

  render() {
    let statusMessage = this.state.status?.success ? (
      <div className="success-msg">
        <i className="fa fa-check"></i>
        {this.state.status.message}
      </div>
    ) : (
      <div className={this.state.status?.success === false ? "error-msg" : ""}>
        <i
          className={
            this.state.status?.success === false ? "fa fa-times-circle" : ""
          }
        ></i>
        {this.state.status?.message || ""}
      </div>
    );

    return (
      <ParticleComponent>
        <div id="main" style={{ display: "flex"}}>
          <article id="contact" className="active timeout">
            <h2 className="major">Contact</h2>
            <p className="align-center">Join our <a href="https://discord.numericaideas.com"><b>Discord Community</b></a> with +150 members</p>
            <form onSubmit={this.handleSubmitForm}>
              <div className="field half first">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  onChange={this.handleOnChange}
                  name="name"
                  value={this.state.formData?.name}
                  disabled={this.state.active}
                  id="name"
                />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  onChange={this.handleOnChange}
                  name="email"
                  value={this.state.formData?.email}
                  disabled={this.state.active}
                  id="email"
                />
              </div>
              <div className="field" style={{ marginBottom: 10 }}>
                <label htmlFor="message">Message</label>
                <textarea
                  onChange={this.handleOnChange}
                  name="message"
                  value={this.state.formData?.message}
                  disabled={this.state.active}
                  id="message"
                  rows="4"
                ></textarea>
              </div>
              <div className="err-msg">{this.state?.errMessage}</div>

              <div className="g-rdecaptcha">
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  size="invisible"
                  sitekey={process.env.NI_GOOGLE_CAPTCHA_API_KEY}
                />
              </div>
              <ul className="actions">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="special"
                    disabled={this.state.active}
                  />
                </li>
                <li>
                  <input type="reset" onClick={this.onReset} value="Reset" />
                </li>
              </ul>
              {statusMessage}
            </form>
            <ul className="icons">
              <li>
                <a
                  href="https://twitter.com/numericaideas"
                  className="icon fa-twitter"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/numericaideas"
                  className="icon fa-facebook"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="label">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/numericaideas"
                  className="icon fa-linkedin"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="label">LinkedIn</span>
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/numerica-ideas"
                  className="icon fa-github"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@numericaideas/channels?sub_confirmation=1"
                  className="icon fa-youtube"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span className="label">YouTube</span>
                </a>
              </li>
            </ul>
            <ul className="actions" style={{ textAlign: "center" }}>
              <li>
                <input type="reset" value="Return" onClick={this.goToHome} />
              </li>
            </ul>
          </article>
        </div>
        <Footer/>
        </ParticleComponent>
    );
  }
}

export default ContactPage;
